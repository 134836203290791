import Container from "~/components/Container";
import { Link } from "react-router-dom";
import React from "react";

type Props = { logoHref?: string; actionElement?: React.ReactNode };
export default function SimpleNavbar({ logoHref, actionElement }: Props) {
  return (
    <div>
      <Container>
        <div className="border-b-800/20 isolate my-4 border-b pb-4">
          <div className="flex items-center justify-between">
            <Link to={logoHref ?? "/"} >
              <img
                src="/buildjet-for-github-actions-logo2.svg"
                alt=""
                className="h-[36px]"
              />
            </Link>

            <div>{actionElement}</div>
          </div>
        </div>
      </Container>
    </div>
  );
}
